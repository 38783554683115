import { Card, Col } from 'antd';
import React, { useCallback } from 'react';
import { Doughnut } from 'react-chartjs-2';

import FormSelectField from '../../../foundation/components/form_select_field/FormSelectField';
import { KeyValue } from '../../../foundation/types';
import { numberFormatter } from '../../../foundation/utils/helperFunctions';
import { PieChartType } from '../redux/types';

type DoughnutChartProps = {
  data: any;
  title: string;
  hasFilter?: boolean;
  filterOptions?: KeyValue[];
  onFilterChange?: (v: string) => void;
  filterValue?: string;
};
const DoughnutChartCard = ({
  data,
  title,
  hasFilter,
  filterOptions,
  onFilterChange,
  filterValue,
}: DoughnutChartProps) => {
  // Memoized callback to prepare data for Doughnut charts
  const getDoughnutGraphData = useCallback(
    (graphData: PieChartType[]) => {
      const labels = graphData.map((item) => item.details);
      const data = graphData.map((item) => item.otherDetails);

      return {
        labels,
        datasets: [
          {
            label: 'Amount',
            data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(255, 206, 86, 0.5)',
              'rgba(75, 192, 192, 0.5)',
              'rgba(153, 102, 255, 0.5)',
              'rgba(255, 159, 64, 0.5)',
              'rgba(98, 128, 227, 0.5)',
              'rgba(207, 68, 68, 0.5)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(98, 128, 227, 1)',
              'rgba(207, 68, 68, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };
    },
    [], // No dependencies needed, as the function only processes input data
  );

  return (
    <Col span={8}>
      <Card
        className="ar-sum-chart__card"
        title={title}
        styles={{
          body: {
            padding: hasFilter ? 10 : 20,
            minHeight: 500,
            maxHeight: 500,
          },
        }}
      >
        {hasFilter && (
          <FormSelectField
            label="Filter"
            onChange={onFilterChange}
            value={filterValue}
            options={filterOptions}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginBottom: 10,
              width: '100%',
            }}
            selectFieldStyle={{ width: 150, marginLeft: 15 }}
          />
        )}
        <Doughnut
          data={getDoughnutGraphData(data)}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'bottom',
              },

              tooltip: {
                callbacks: {
                  label: (context: any) => {
                    return `Amount: ${numberFormatter(context.raw.amount, '$')}\n Count: ${context.raw.count}`;
                  },
                },
              },
            },
            parsing: {
              key: 'amount',
            },
          }}
        />
      </Card>
    </Col>
  );
};

export default DoughnutChartCard;
