import { SaveOutlined, SlackOutlined } from '@ant-design/icons';
import { message, Modal, notification } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import WysiwygEditor from '../../../foundation/components/wysiwyg_editor/WysiwygEditor';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import { addNotes, fetchNotes } from '../redux/async_thunks';
import { selectNotes } from '../redux/selectors';
import NotesViewer from './NotesViewer';

type NotesProps = {
  handleClose: () => void;
  clientId?: number;
  clientIds?: number[];
  IsBulk: boolean;
};

const Notes = ({ handleClose, clientId, clientIds, IsBulk }: NotesProps) => {
  const dispatch = useAppDispatch();

  const viewerRef = useRef<any>(null);

  const [messageApi, contextHolder] = message.useMessage();

  const [isNotesLoading, setNotesLoading] = useState(true);

  const [isDragginDisabled, disableDraggging] = useState(true);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const user = useSelector(selectUser);

  const notes = useSelector(selectNotes);

  const draggleRef = useRef<HTMLDivElement | null>(null);

  const editorRef: any = useRef(null);

  const fetchNotesData = async () => {
    if (user) {
      try {
        if (IsBulk) {
          return setNotesLoading(false);
        }
        if (clientId) {
          const data = {
            token: user.token,
            clientId,
          };

          await dispatch(fetchNotes(data)).unwrap();
        }

        setNotesLoading(false);
      } catch (error) {
        setNotesLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchNotesData();
  }, []);

  useEffect(() => {
    if (notes.length > 0) {
      viewerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [notes]);

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const title = useMemo(
    () => (
      <div
        style={{
          width: '100%',
          cursor: 'move',
        }}
        onMouseOver={() => {
          if (isDragginDisabled) {
            disableDraggging(false);
          }
        }}
        onMouseOut={() => {
          disableDraggging(true);
        }}
      >
        Notes
      </div>
    ),
    [],
  );

  const handleCancel = () => {
    setNotesLoading(false);
    handleClose();
  };

  const handleOk = (sendSlack: boolean) => async () => {
    try {
      if (editorRef.current && user) {
        const content = editorRef.current.getContent({ format: 'text' });
        if (content === '') {
          messageApi.open({
            type: 'warning',
            content: 'Notes are required',
          });
          return;
        }

        setNotesLoading(true);
        let clients: number[] = [];

        if (IsBulk && clientIds) {
          clients = clientIds;
        } else if (clientId) {
          clients = [clientId];
        }

        const data = {
          userId: user?.user_id,
          sessionId: user?.session_id,
          message: content,
          sendSlackMessage: sendSlack,
          clientIds: clients,
        };

        await dispatch(addNotes({ reqBody: data, token: user.token })).unwrap();

        editorRef.current.setContent('');

        setNotesLoading(false);

        handleClose();

        notification.success({
          message: 'Success!',
          description: 'Note saved successfully.',
        });
      }
    } catch (error) {
      setNotesLoading(false);
    }
  };

  return (
    <Modal
      okButtonProps={{
        style: {
          width: 100,
        },
      }}
      okText={
        <span>
          <SlackOutlined /> Slack
        </span>
      }
      title={title}
      styles={{ body: { minHeight: 400 } }}
      open={true}
      onOk={handleOk(true)}
      maskClosable={false}
      onCancel={handleCancel}
      cancelText={
        <span>
          <SaveOutlined /> Save
        </span>
      }
      cancelButtonProps={{
        onClick: handleOk(false),
      }}
      modalRender={(modal) => (
        <Draggable
          disabled={isDragginDisabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      className="ar-notes"
    >
      {contextHolder}
      {isNotesLoading && <FullPageLoader />}
      {!IsBulk && <NotesViewer notes={notes} ref={viewerRef} />}
      <WysiwygEditor initialValue={''} ref={editorRef} />
    </Modal>
  );
};

export default Notes;
