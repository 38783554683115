import { Avatar, Card } from 'antd';
import React from 'react';
import CountUp from 'react-countup';

type Props = {
  icon: React.ReactNode;
  formatNumber?: boolean;
  label: string;
  background?: string;
  color?: string;
  stats: number;
};

const StatsCard = ({
  icon,
  formatNumber,
  label,
  stats,
  background,
  color,
}: Props) => {
  return (
    <Card className="ar-details__card ar-details__card--stats-card">
      <div>
        {' '}
        <Avatar
          size={60}
          icon={icon}
          style={{ marginBottom: 10, background, color }}
          className="ar-details__stats-card-icon-wrapper"
        />
      </div>

      <div>
        <div className="ar-details__stats-card-label-wrapper">{label}</div>
        {formatNumber && (
          <div className="ar-details__stats-card-num-wrapper">
            $
            <CountUp end={stats} duration={1} decimals={2} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default StatsCard;
