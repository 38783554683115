import { Avatar, Card } from 'antd';
import React from 'react';
import CountUp from 'react-countup';

type Props = {
  icon: React.ReactNode; // Icon to be displayed in the avatar
  formatNumber?: boolean; // Flag to determine if the number should be formatted as currency
  label: string; // Label to describe the stats
  background?: string; // Background color for the avatar
  color?: string; // Color for the avatar icon
  stats: number; // Statistics number to display
};

const StatsCard: React.FC<Props> = ({
  icon,
  formatNumber = false,
  label,
  stats,
  background,
  color,
}) => {
  return (
    <Card className="ar-summary__card ar-summary__card--stats-card">
      {/* Avatar displaying the icon */}
      <div>
        <Avatar
          size={60}
          icon={icon}
          style={{ marginBottom: 10, background, color }}
          className="ar-summary__stats-card-icon-wrapper"
        />
      </div>

      {/* Wrapper for the label and stats */}
      <div className="ar-summary__stats-card-details-wrapper">
        {/* Label for the stats */}
        <div className="ar-summary__stats-card-label-wrapper">{label}</div>

        {/* Conditional rendering of stats based on formatNumber prop */}
        <div className="ar-summary__stats-card-num-wrapper">
          {formatNumber ? (
            <>
              $
              <CountUp end={stats} duration={1} decimals={2} />
            </>
          ) : (
            <>
              <CountUp end={stats} duration={1} decimals={2} />%
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default StatsCard;
