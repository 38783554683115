import { Modal, Tabs } from 'antd';
import React from 'react';

import KpiPeriods from './Settings/kpi_periods/KpiPeriods';

type AdminProps = {
  handleClose: () => void;
};

const Admin = ({ handleClose }: AdminProps) => {
  const tabItems = [
    {
      label: 'KPI Periods',
      key: 'kpi-periods',
      children: (
        <div className="l-admin__tab">
          <KpiPeriods />
        </div>
      ),
    },
  ];

  return (
    <Modal
      width={1000}
      title={'Admin Settings'}
      styles={{ body: { minHeight: 400, width: '100%', marginTop: '15px' } }}
      open={true}
      maskClosable={true}
      onCancel={handleClose}
      footer={null}
      centered
    >
      <div className="l-admin">
        <Tabs
          tabPosition="left"
          items={tabItems}
          type="card"
          tabBarStyle={{ minWidth: '150px' }}
        />
      </div>
    </Modal>
  );
};

export default Admin;
