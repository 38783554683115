import { createSlice } from '@reduxjs/toolkit';

import { fetchDashboard } from './async_thunks';
import { DashboardState } from './types';

const initialState: DashboardState = {
  summary: undefined,
  invoiceStatusTracker: [],
  paymentTerms: [],
  overdueInvoices: [],
  invoiceAgeingReport: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchDashboard.fulfilled, (state, action) => {
      state.summary = action.payload.summary;
      state.invoiceStatusTracker = action.payload.invoiceStatusTracker;
      state.paymentTerms = action.payload.paymentTerms;
      state.overdueInvoices = action.payload.overdueInvoices;
      state.invoiceAgeingReport = action.payload.invoiceAgeingReport;
    });
  },
});

export default dashboardSlice.reducer;
