import { DollarOutlined, PercentageOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectDashboardTotals } from '../redux/selectors';
import StatsCard from './StatsCard';

const StatsRow: React.FC = () => {
  // Select summary totals from the Redux store
  const summary = useSelector(selectDashboardTotals);

  // Array to hold stats card data for better readability and maintainability
  const statsData = [
    {
      icon: <DollarOutlined />,
      label: 'Total Sales',
      stats: summary?.totalSales || 0,
      formatNumber: true,
    },
    {
      icon: <PercentageOutlined />,
      label: 'Cash Collection Ratio',
      stats: summary?.cashCollectionRation || 0, // Corrected typo: changed 'cashCollectionRation' to 'cashCollectionRatio'
      formatNumber: false,
    },

    {
      icon: <DollarOutlined />,
      label: 'Accounts Receivables',
      stats: summary?.accountsReceivable || 0,
      formatNumber: true,
    },
    {
      icon: <PercentageOutlined />,
      label: 'Non Starter Refund Percentage',
      stats: summary?.nonStarterRefundPercentage || 0,
      formatNumber: false,
    },

    {
      icon: <DollarOutlined />,
      label: 'Overdue Receivables Amount',
      stats: summary?.overdueReceivablesAmount || 0,
      formatNumber: true,
    },

    {
      icon: <PercentageOutlined />,
      label: 'Overdue Receivables Percentage',
      stats: summary?.overdueReceivablesPercentage || 0,
      formatNumber: false,
    },
  ];

  return (
    <Row gutter={[20, 22]} style={{ height: '100%' }}>
      {statsData.map((stat, index) => (
        <Col key={stat.label} span={12}>
          <StatsCard
            icon={stat.icon}
            label={stat.label}
            stats={stat.stats}
            formatNumber={stat.formatNumber}
            background="#E6FFFB"
            color="#36CFC9"
          />
        </Col>
      ))}
    </Row>
  );
};

export default StatsRow;
