import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  classNames?: string;
  style?: React.CSSProperties;
};

function FullPageLoader({ classNames, style }: Props): JSX.Element {
  return (
    <div className={`full-page-loader ${classNames || ''}`} style={style}>
      <Spin />
    </div>
  );
}

// PropTypes
FullPageLoader.propTypes = {
  classNames: PropTypes.string,
};

export default FullPageLoader;
