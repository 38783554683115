import { RootState } from '../../../store';

export const selectKpiPeriodOptions = (state: RootState) => {
  const kpiPeriods = state.admin.values.kpi_periods;

  let options: { label: string; value: number | null }[] = [];

  if (kpiPeriods.length) {
    options = kpiPeriods.map(({ key, value }) => ({
      label: key,
      value: value,
    }));
  }

  options.unshift({ value: null, label: 'None' });

  return options;
};

export const selectKpiPeriodDefaultId = (state: RootState) => {
  const kpiPeriods = state.admin.values.kpi_periods;

  if (kpiPeriods.length) {
    // Set to the first KPI period option ID for now
    return kpiPeriods[0].value;
  }

  return null;
};

export const selectKpiPeriods = (state: RootState) =>
  state.admin.kpi_periods_list;
