import { Button, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import FullPageLoader from '../../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../../../../store/hooks';
import { selectUser } from '../../../../authentication/redux/selectors';
import { deleteKpiPeriod } from '../../../redux/async_thunks';
import { KPIPeriod } from '../../../redux/types';

type KpiPeriodDeleteProps = {
  handleClose: () => void;
  kpiPeriodDelete: KPIPeriod | null;
};

const KpiPeriodDelete = ({
  handleClose,
  kpiPeriodDelete,
}: KpiPeriodDeleteProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    if (user && kpiPeriodDelete) {
      try {
        setIsLoading(true);

        const data = {
          userId: user.user_id,
          sessionId: user.session_id,
          kpiPeriodId: kpiPeriodDelete?.kpiPeriodId,
        };

        await dispatch(
          deleteKpiPeriod({
            token: user.token,
            reqBody: data,
          }),
        ).unwrap();

        setIsLoading(false);
        handleClose();
        notification.success({
          message: 'Success!',
          description: 'KPI Period has been deleted.',
        });
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      width={400}
      title={'Delete KPI Period'}
      open={true}
      maskClosable={true}
      onCancel={handleClose}
      footer={null}
      centered
      className="l-admin__modal"
    >
      {isLoading && <FullPageLoader style={{ borderRadius: '8px' }} />}
      <p>
        Are you sure you want to delete{' '}
        <strong>{kpiPeriodDelete?.kpiPeriod}</strong>?
      </p>
      <div className="l-admin__btn-group">
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleDelete} type="primary">
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default KpiPeriodDelete;
