import { notification } from 'antd';
import axios from 'axios';

import { decryptResponseMessage } from '../utils/api';
import { clearStorage } from '../utils/storageHandler';

const Axios = axios.create({});

const showWarningMessage = (warningMessage: string) => {
  if (
    warningMessage &&
    warningMessage !== '' &&
    warningMessage !== null &&
    warningMessage !== undefined
  ) {
    notification.info({
      message: 'Info',
      description: warningMessage,
    });
  }
};

// Add a request interceptor
Axios.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === 206) {
      /**
       * NOTE: This is dashdot's internal logic that we are using to show info
       * message in API.
       */
      showWarningMessage(
        decryptResponseMessage(response.data, 'responseMessage'),
      );

      return Promise.reject();
    } else {
      return response;
    }
  },
  (error) => {
    if (error.response.status === 403) {
      clearStorage();
      window.close();
    }
    let errorDescription;
    const responseError = decryptResponseMessage(
      error?.response?.data,
      'resMessage',
    );

    if (responseError) {
      errorDescription = responseError;
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    notification.error({
      message: 'Error',
      // @ts-ignore
      description: errorDescription,
    });
    return Promise.reject(error);
  },
);

export default Axios;
