export const numberFormatter = (num: any, unit: string, dontFix?: boolean) => {
  if (num !== null && num !== undefined) {
    let isNegative = '';
    if (typeof num === 'number') {
      isNegative = num < 0 ? '-' : '';
    } else {
      isNegative = parseInt(num) < 0 ? '-' : '';
    }
    const parts = !dontFix
      ? Math.abs(parseFloat(num)).toFixed(2).toString().split('.')
      : Math.abs(parseFloat(num)).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `${isNegative}${unit}${parts.join('.')}`;
  }
};

export const percentageParser = (num: any) => {
  if (num !== null && num !== undefined) {
    return parseFloat((parseFloat(num) * 100).toString()).toFixed(2);
  }
};

export const percentageParserForBE = (num: any) => {
  if (num !== null && num !== undefined) {
    return parseFloat((parseFloat(num) / 100).toString()).toFixed(2);
  }
};

export const percentageFormatter = (num: any, hasSymbol = true) => {
  if (num !== null && num !== undefined) {
    return `${parseFloat(num).toFixed(2)}${hasSymbol ? '%' : ''}`;
  }
};

export const hexToRgbA = (hex: string, alpha: string) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    );
  }
  throw new Error('Bad Hex');
};
