import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import { fetchKpiPeriods } from '../../redux/async_thunks';
import { selectKpiPeriods } from '../../redux/selectors';
import { KPIPeriod } from '../../redux/types';
import KpiPeriodDelete from './modals/KpiPeriodDelete';
import KpiPeriodForm from './modals/KpiPeriodForm';

const KpiPeriods = () => {
  const DATE_FORMAT = 'MMMM DD, YYYY';

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const kpiPeriodsData = useSelector(selectKpiPeriods);

  const [isLoading, setIsLoading] = useState(false);
  const [kpiPeriodEdit, setKpiPeriodEdit] = useState<KPIPeriod | null>(null);
  const [kpiPeriodDelete, setKpiPeriodDelete] = useState<KPIPeriod | null>(
    null,
  );
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const fetchData = async () => {
    if (user) {
      setIsLoading(true);
      try {
        await dispatch(
          fetchKpiPeriods({
            token: user.token,
          }),
        ).unwrap();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'kpiPeriod',
      key: 'kpiPeriod',
    },
    {
      title: 'Start Date',
      dataIndex: 'kpiStart',
      key: 'kpiStart',
      render: (data: string) => {
        return dayjs(data).format(DATE_FORMAT);
      },
    },
    {
      title: 'End Date',
      dataIndex: 'kpiEnd',
      key: 'kpiEnd',
      render: (data: string) => {
        return dayjs(data).format(DATE_FORMAT);
      },
    },
    {
      title: 'Actions',
      dataIndex: 'kpiPeriodId',
      key: 'kpiPeriodId',
      width: 75,
      render: (id: string, data: KPIPeriod) => {
        return (
          <div className="l-admin__btn-group l-admin__btn-group--table">
            <button
              className="l-admin__btn"
              onClick={() => {
                setKpiPeriodEdit(data);
                setIsFormModalVisible(true);
              }}
            >
              <EditOutlined />
            </button>
            <button
              className="l-admin__btn"
              onClick={() => {
                setKpiPeriodDelete(data);
                setIsDeleteModalVisible(true);
              }}
            >
              <DeleteOutlined />
            </button>
          </div>
        );
      },
    },
  ];

  const handleFormModalClose = () => {
    setKpiPeriodEdit(null);
    setIsFormModalVisible(false);
  };

  const handleDeleteModalClose = () => {
    setKpiPeriodDelete(null);
    setIsDeleteModalVisible(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isFormModalVisible && (
        <KpiPeriodForm
          dateFormat={DATE_FORMAT}
          handleClose={handleFormModalClose}
          kpiPeriodEdit={kpiPeriodEdit}
        />
      )}
      {isDeleteModalVisible && (
        <KpiPeriodDelete
          handleClose={handleDeleteModalClose}
          kpiPeriodDelete={kpiPeriodDelete}
        />
      )}
      {isLoading && <FullPageLoader style={{ borderRadius: '8px' }} />}
      <h6 className="l-admin__heading">KPI Periods List</h6>
      <Table
        dataSource={kpiPeriodsData}
        bordered
        size="middle"
        columns={columns}
        loading={isLoading}
        pagination={false}
        rowKey={'kpiPeriodId'}
        scroll={{ y: 400 }}
      />
      <div className="l-admin__btn-group">
        <Button
          type="primary"
          onClick={() => {
            setIsFormModalVisible(true);
          }}
        >
          Add KPI Period
        </Button>
      </div>
    </>
  );
};

export default KpiPeriods;
