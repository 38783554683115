import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectDetailValues = (state: RootState) => state.detail.values;

export const selectDetailClientTypes = (state: RootState) =>
  state.detail.values?.clientTypes;

export const selectDetailInvoiceStatus = (state: RootState) =>
  state.detail.values?.invoiceStatus;

export const selectDetailPaymentTerms = (state: RootState) =>
  state.detail.values?.paymentTerms;

export const selectOverdueInvoicesTypes = (state: RootState) =>
  state.detail.values?.overdueInvoicesTypes;

export const selectDetail = (state: RootState) => state.detail.clients;

export const selectDetailTotals = (state: RootState) =>
  state.detail.detailTotals;

export const selectNotes = (state: RootState) => state.detail.notes;
