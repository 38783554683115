import { Avatar, Empty, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { forwardRef } from 'react';

import { Note } from '../redux/types';

type NotesViewerProps = {
  notes: Note[];
};

// Function to get initials from a name
const getInitials = (name) => {
  const nameParts = name.split(' ');
  if (nameParts.length === 1) {
    return nameParts[0][0].toUpperCase();
  } else {
    return (nameParts[0][0] + nameParts[1][0]).toUpperCase();
  }
};

const NotesViewer = ({ notes }: NotesViewerProps, ref: any) => {
  return (
    <div className="ar-notes__notes-viewer-wrapper">
      <div className="ar-notes__notes-viewer">
        {/* Display a message if there are no notes */}
        {notes.length === 0 && <Empty description="No notes" />}

        {/* Iterate over the notes array and display each note */}
        {notes.map((note) => (
          <div className="ar-notes__note-wrapper" key={note.messageId}>
            <div className="ar-notes__note-info-wrapper">
              <div
                className="ar-notes__note"
                style={{
                  transition: 'opacity 1s',
                }}
              >
                <p style={{ margin: 0 }}>{note.message}</p>
              </div>
              <div>
                <span
                  style={{ marginLeft: '5px', fontSize: '12px', color: '#888' }}
                >
                  {dayjs(note.dateTime).format('YYYY-MM-DD HH:mm')}
                </span>
              </div>
            </div>
            <Tooltip title={note.sender}>
              <Avatar style={{ backgroundColor: '#00766c', cursor: 'pointer' }}>
                {getInitials(note.sender)}
              </Avatar>
            </Tooltip>
          </div>
        ))}
        {/* Reference to scroll to the bottom */}
        <div ref={ref} />
      </div>
    </div>
  );
};

export default forwardRef(NotesViewer);
