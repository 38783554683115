import {
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Layout, MenuProps } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import Logo from '../../../foundation/assets/svgs/Logo.svg';
import {
  clearStorage,
  getItemFromStorage,
} from '../../../foundation/utils/storageHandler';
import env_constants from '../../../internals/env/env_constants.json';
import { useAppDispatch } from '../../../store/hooks';
import Admin from '../../admin/Admin';
import { logout } from '../../authentication/redux/async_thunks';
import {
  selectIsAdmin,
  selectUser,
} from '../../authentication/redux/selectors';

const { Header: LayoutHeader } = Layout;

type HeaderProps = {
  setIsAppLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isAppLoading: boolean;
};

const Header = ({ setIsAppLoading, isAppLoading }: HeaderProps) => {
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const isAdmin = useSelector(selectIsAdmin);

  const location = useLocation();

  const navigate = useNavigate();

  const [isAdminModalVisible, setIsAdminModalVisible] = useState(false);

  const full_name = useMemo(() => {
    if (user) {
      return `${user.first_name} ${user.last_name}`;
    } else {
      return `User`;
    }
  }, [user]);

  const handleLogout = async () => {
    if (isAppLoading) {
      return;
    }
    const getUser = getItemFromStorage('user');
    // @ts-ignore
    const userStorage = JSON.parse(getUser);
    const fixUser = user || userStorage;

    try {
      if (fixUser) {
        setIsAppLoading(true);
        const data: any = {
          sessionId: fixUser.session_id,
        };

        data.appId = env_constants.APP_ID;
        data.userId = fixUser?.user_id;

        await dispatch(
          logout({
            token: fixUser?.token,
            data,
          }),
          // @ts-ignore
        ).unwrap();
        clearStorage();

        setIsAppLoading(false);

        window.close();
      }
    } catch (error) {
      console.log(error);
      setIsAppLoading(false);
    }
  };

  const handleAdminModalClose = () => {
    setIsAdminModalVisible(false);
  };

  const menu = useMemo(() => {
    const items: MenuProps['items'] = [
      {
        key: '1',
        icon: <LogoutOutlined />,
        onClick: handleLogout,
        label: `Logout`,
      },
    ];

    if (isAdmin) {
      items.unshift({
        key: '0',
        icon: <SettingOutlined />,
        onClick: () => {
          setIsAdminModalVisible(true);
        },
        label: `Admin Settings`,
      });
    }

    return items;
  }, [user]);

  const currentPage = useMemo(() => {
    if (location.pathname === '/detail') {
      return 'Detail';
    }

    return 'Dashboard';
  }, [location]);

  const handleNavClick = useCallback(
    (path: string) => (e) => {
      e.preventDefault();
      navigate(path);
    },
    [navigate],
  );

  return (
    <LayoutHeader className="c-app-layout__header">
      {isAdminModalVisible && <Admin handleClose={handleAdminModalClose} />}
      <div className="c-app-layout__header-left-side">
        <div className="c-app-layout__header-logo">
          {env_constants.Environ !== 'Demo' && <Logo />}
        </div>
        <div className="c-app-layout__header-navigation">
          <button
            onClick={handleNavClick('/dashboard')}
            className={`c-app-layout__header-navigation-item ${
              currentPage === 'Dashboard' &&
              ' c-app-layout__header-navigation-item--active'
            }`}
          >
            Dashboard
          </button>
          <button
            onClick={handleNavClick('/detail')}
            className={`c-app-layout__header-navigation-item ${
              currentPage === 'Detail' &&
              ' c-app-layout__header-navigation-item--active'
            }`}
          >
            Details
          </button>
        </div>
      </div>
      <div className="c-app-layout__header-right-side ">
        <Dropdown menu={{ items: menu }}>
          <div className="c-app-layout__header-user">
            <div style={{ marginRight: 5 }}>{full_name}</div>
            <div style={{ marginRight: 5 }}>
              <Avatar src={user?.picture} icon={<UserOutlined />} />
            </div>
            <div style={{ marginRight: 25, fontSize: 12 }}>
              <DownOutlined />
            </div>
          </div>
        </Dropdown>
      </div>
    </LayoutHeader>
  );
};

export default Header;
