import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { setKpiPeriods } from './slice';
import { KPIPeriodOption } from './types';

export const fetchKpiPeriods = createAsyncThunk(
  'admin/fetchKpiPeriods',
  async (apiParams: { token: string }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.AR_API_BASE_URL}/Admin/kpi-period`,
      options,
    );

    const data = decryptData(response.data);

    return data.kpiPeriods;
  },
);

type CreateKpiPeriodReqBody = {
  kpiPeriod: string;
  kpistart: string;
  kpiEnd: string;
  userId: string;
  sessionId: string;
};

export const createKpiPeriod = createAsyncThunk(
  'admin/createKpiPeriod',
  async (
    apiParams: { token: string; reqBody: CreateKpiPeriodReqBody },
    thunkAPI,
  ) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.reqBody);

    const response = await axios.post(
      `${env_constants.AR_API_BASE_URL}/Admin/kpi-period`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    thunkAPI.dispatch(
      setKpiPeriods(
        data.kpiPeriods.map((k: { kpiPeriod: string; kpiPeriodId: string }) => {
          return {
            key: k.kpiPeriod,
            value: k.kpiPeriodId,
          };
        }),
      ),
    );

    return data.kpiPeriods;
  },
);

type UpdateKpiPeriodReqBody = {
  kpiPeriod: string;
  kpiPeriodId: number;
  kpistart: string;
  kpiEnd: string;
  userId: string;
  sessionId: string;
};

export const updateKpiPeriod = createAsyncThunk(
  'admin/updateKpiPeriod',
  async (
    apiParams: { token: string; reqBody: UpdateKpiPeriodReqBody },
    thunkAPI,
  ) => {
    const state: any = thunkAPI.getState();
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.reqBody);

    const response = await axios.put(
      `${env_constants.AR_API_BASE_URL}/Admin/kpi-period`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    thunkAPI.dispatch(
      setKpiPeriods(
        state.admin.values.kpi_periods.map((kpiPeriod: KPIPeriodOption) =>
          kpiPeriod.value === data.kpiPeriod.kpiPeriodId
            ? {
                key: data.kpiPeriod.kpiPeriod,
                value: data.kpiPeriod.kpiPeriodId,
              }
            : kpiPeriod,
        ),
      ),
    );

    return data.kpiPeriod;
  },
);

type DeleteKpiPeriodReqBody = {
  kpiPeriodId: number;
  userId: string;
  sessionId: string;
};

export const deleteKpiPeriod = createAsyncThunk(
  'admin/deleteKpiPeriod',
  async (
    apiParams: { token: string; reqBody: DeleteKpiPeriodReqBody },
    thunkAPI,
  ) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.reqBody);

    const response = await axios.delete(
      `${env_constants.AR_API_BASE_URL}/Admin/kpi-period`,
      {
        ...options,
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);

    thunkAPI.dispatch(
      setKpiPeriods(
        data.kpiPeriods.map((k: { kpiPeriod: string; kpiPeriodId: string }) => {
          return {
            key: k.kpiPeriod,
            value: k.kpiPeriodId,
          };
        }),
      ),
    );

    return data.kpiPeriods;
  },
);
