import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectDashboardTotals = (state: RootState) =>
  state.dashboard.summary;

export const selectDashboardInvoiceStatusTracker = (state: RootState) =>
  state.dashboard.invoiceStatusTracker;

export const selectDashboardPaymentTerms = (state: RootState) =>
  state.dashboard.paymentTerms;

export const selectDashboardOverdueInvoices = (state: RootState) =>
  state.dashboard.overdueInvoices;

export const selectDashboardInvoiceAgeingReport = (state: RootState) =>
  state.dashboard.invoiceAgeingReport;
