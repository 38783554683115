import { Buffer } from 'buffer';
import CryptoES from 'crypto-es';

import envConstant from '../../internals/env/env_constants.json';
import { getItemFromStorage } from './storageHandler';

const b64ToUTF8 = (str: string) => {
  return Buffer.from(str, 'base64').toString('utf8');
};

const UTFKey = CryptoES.enc.Utf8.parse(b64ToUTF8(envConstant.DEFAULT_PASS_KEY));
const UTFIV = CryptoES.enc.Utf8.parse(b64ToUTF8(envConstant.DEFAULT_PASS_IV));

export const encryptData = (data: any, keyIV?: any[]) => {
  const fixedData = typeof data === 'string' ? data : JSON.stringify(data);
  if (keyIV) {
    // for custom Key and IV
    const encrypted = CryptoES.AES.encrypt(
      fixedData,
      CryptoES.enc.Utf8.parse(b64ToUTF8(keyIV[0])),
      {
        // @ts-ignore
        iv: CryptoES.enc.Utf8.parse(b64ToUTF8(keyIV[1])),
        format: CryptoES.format.Hex,
        mode: CryptoES.mode.CBC,
        padding: CryptoES.pad.Pkcs7,
      },
    );

    return encrypted.toString();
  }
  const encrypted = CryptoES.AES.encrypt(fixedData, UTFKey, {
    // @ts-ignore
    iv: UTFIV,
    // format: CryptoES.format.Hex,
    mode: CryptoES.mode.CBC,
    // padding: CryptoES.pad.Pkcs7,
  });

  return encrypted.toString();
};

export const decryptData = (data: any, keyIV?: any[]) => {
  if (keyIV) {
    // for custom Key and IV
    const decrypted = CryptoES.AES.decrypt(
      data,
      CryptoES.enc.Utf8.parse(b64ToUTF8(keyIV[0])),
      {
        iv: CryptoES.enc.Utf8.parse(b64ToUTF8(keyIV[1])),
        format: CryptoES.format.Hex,
        mode: CryptoES.mode.CBC,
        padding: CryptoES.pad.Pkcs7,
      },
    );
    return JSON.parse(decrypted.toString(CryptoES.enc.Utf8));
  }

  const decrypted = CryptoES.AES.decrypt(data, UTFKey, {
    iv: UTFIV,
    // format: CryptoES.format.Hex,
    mode: CryptoES.mode.CBC,
    // padding: CryptoES.pad.Pkcs7,
  });
  return JSON.parse(decrypted.toString(CryptoES.enc.Utf8));
};

export const getClientId = (clientId?: string | undefined) => {
  return clientId || getSessionClientID();
};

export const getSessionClientID = () => {
  const userAgent = window.navigator.userAgent;
  const getClientIP = getItemFromStorage('client_ip');
  const userString = getItemFromStorage('user');
  const user = userString ? JSON.parse(userString) : {};

  const clientIP = getClientIP ? JSON.parse(getClientIP) : null;
  const clientID = encryptData(
    `${userAgent}/${clientIP.ipv4}/${user.session_id}`,
  );
  return clientID;
};

export const decryptResponseMessage = (responseData: string, key: string) => {
  if (responseData) {
    const decryptedRes = decryptData(responseData);

    if (decryptedRes && (decryptedRes[key] || decryptedRes.responseMessage)) {
      return decryptedRes[key] || decryptedRes.responseMessage;
    }
    return '';
  }
};
