import { Card } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectDashboardInvoiceAgeingReport } from '../redux/selectors';
import BarGraph from './Bar';

const BarChartGraphCard = () => {
  const invoiceAgeingReport = useSelector(selectDashboardInvoiceAgeingReport);
  // Memoized calculation of bar graph data
  const barGraphData = useMemo(() => {
    const labels = ['0 - 30 Days', '31 - 60 Days', '61 - 90 Days', '90+ Days'];
    const data = invoiceAgeingReport.length ? invoiceAgeingReport[0].data : [];

    return {
      labels,
      datasets: [
        {
          label: 'Invoice Ageing',
          data,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
        },
      ],
    };
  }, [invoiceAgeingReport]);

  return (
    <Card
      className="ar-sum-chart__card"
      title="Invoice Ageing Report"
      style={{ height: '100%' }}
    >
      <BarGraph data={barGraphData} />
    </Card>
  );
};

export default BarChartGraphCard;
