import { Select } from 'antd';
import React, { useMemo } from 'react';

import { KeyValue } from '../../types';

type SelectType = {
  options?: KeyValue[];
  value: string | undefined | number;
  error?: string;
  onChange: any;
  label: string;
  showSearch?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  selectFieldStyle?: React.CSSProperties;
};

export default ({
  options,
  value,
  onChange,
  error,
  label,
  showSearch,
  disabled = false,
  style,
  selectFieldStyle,
}: SelectType) => {
  const formattedOptions = useMemo(() => {
    if (options) {
      return options.map((item) => ({
        label: item.key,
        value: item.value,
      }));
    }
  }, [options]);

  return (
    <div className="c-form-field" style={style}>
      <div className="c-form-field__label">{label}:</div>
      <div className="c-form-field__wrapper">
        <Select
          options={formattedOptions}
          value={value}
          onChange={onChange}
          className="c-select"
          showSearch={showSearch}
          disabled={disabled}
          style={selectFieldStyle}
        />

        <div className="c-form-field__error">{error}</div>
      </div>
    </div>
  );
};
