import { Layout } from 'antd';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { selectUser } from '../authentication/redux/selectors';
import Header from './header/Header';

const { Content } = Layout;

type AppLayoutProps = {
  children: ReactNode;
};

const AppLayout = ({ children }: AppLayoutProps) => {
  const [isAppLoading, setIsAppLoading] = useState(false);
  const user = useSelector(selectUser);

  if (!user) {
    return children;
  }

  return (
    <Layout className="c-app-layout">
      <Header setIsAppLoading={setIsAppLoading} isAppLoading={isAppLoading} />
      <Content className="c-app-layout__content">
        {isAppLoading ? <FullPageLoader /> : children}
      </Content>
    </Layout>
  );
};

export default AppLayout;
