import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchDetails, fetchNotes, fetchValues } from './async_thunks';
import { DetailState, Note } from './types';

const initialState: DetailState = {
  values: undefined,
  clients: [],
  detailTotals: undefined,
  notes: [],
};

export const detailsSlice = createSlice({
  name: 'detail',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNotes: (state, action: PayloadAction<Note[]>) => {
      state.notes = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchValues.fulfilled, (state, action) => {
        state.values = action.payload;
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.notes = action.payload;
      })
      .addCase(fetchDetails.fulfilled, (state, action) => {
        state.clients = action.payload.clients;
        state.detailTotals = action.payload.dashboardTotals;
      });
  },
});

export const { setNotes } = detailsSlice.actions;

export default detailsSlice.reducer;
