import React from 'react';
import { Bar } from 'react-chartjs-2';

import { numberFormatter } from '../../../foundation/utils/helperFunctions';

const BarGraph = ({ data }: any) => {
  return (
    <Bar
      options={{
        indexAxis: 'x' as const,
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'right' as const,
            display: false,
          },

          tooltip: {
            callbacks: {
              label: (context: any) => {
                return ` Total Amount: ${numberFormatter(context.raw.totalAmount, '$')}\n Total Count: ${context.raw.totalCount}`;
              },
            },
          },
        },
        parsing: {
          yAxisKey: 'totalAmount',
          xAxisKey: 'details',
        },
      }}
      data={data}
    />
  );
};

export default BarGraph;
