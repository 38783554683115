import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

export type FetchDashboardReqBody = {
  userId: string;
  sessionId: string;
  kpiPeriodId?: number;
  closedWonStart?: string | null;
  closedWonEnd?: string | null;
};
export const fetchDashboard = createAsyncThunk(
  'dashboard/fetch',
  async (apiParams: { token: string; reqBody: FetchDashboardReqBody }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.reqBody);

    const response = await axios.post(
      `${env_constants.AR_API_BASE_URL}/Dashboard`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);
