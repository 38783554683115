import { Button, Col, Collapse, DatePicker, GetProps, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectKpiPeriodDefaultId,
  selectKpiPeriodOptions,
} from '../../admin/redux/selectors';

type FiltersProps = {
  onFilter: (v?: any) => void;
  isLoading: boolean;
};
const Filters = ({ onFilter, isLoading }: FiltersProps) => {
  const kpiPeriodOptions = useSelector(selectKpiPeriodOptions);
  const kpiPeriodDefaultId = useSelector(selectKpiPeriodDefaultId);

  const initialValues = useMemo(
    () => ({
      kpiPeriodId: kpiPeriodDefaultId,
      closedWonStart: undefined,
      closedWonEnd: undefined,
    }),
    [kpiPeriodDefaultId],
  );

  const removeUndefinedKeys = (obj) => {
    for (const key in obj) {
      if (!obj[key] || obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  };

  const handleFormSubmit = async (values: any) => {
    const updatedValues = removeUndefinedKeys(values);

    const reqBody = updatedValues?.kpiPeriodId
      ? {
          // If kpi period is not null then closed won dates should be null
          ...updatedValues,
          kpiPeriodId: updatedValues.kpiPeriodId,
          closedWonStart: null,
          closedWonEnd: null,
        }
      : {
          // If kpi period is null then both closedwon dates should not be null
          ...updatedValues,
          kpiPeriodId: null,
          closedWonStart: dayjs(updatedValues.closedWonStart).format(
            'YYYY-MM-DD',
          ),
          closedWonEnd: dayjs(updatedValues.closedWonEnd).format('YYYY-MM-DD'),
        };

    onFilter(reqBody);
  };
  /**
   * Formik form initialization
   */
  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  const [values, setFieldValue, dirty, handleSubmit, resetForm] =
    useMemo(() => {
      return [
        formik.values,
        formik.setFieldValue,
        formik.dirty,
        formik.handleSubmit,
        formik.resetForm,
      ];
    }, [formik]);

  const resetFormValues = () => {
    resetForm();
    onFilter();
  };

  const isKPIPeriodDisabled = useMemo(
    () => values.closedWonStart || values.closedWonEnd,
    [values],
  );

  const isApplyFilterDisabled = useMemo(() => {
    if (
      !dirty ||
      isLoading ||
      (values.kpiPeriodId &&
        (values.closedWonEnd !== undefined ||
          values.closedWonStart !== undefined)) ||
      (!values.kpiPeriodId && (!values.closedWonEnd || !values.closedWonStart))
    ) {
      return true;
    } else {
      return false;
    }
  }, [dirty, isLoading, values]);

  type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs(values.closedWonStart).endOf('day');
  };

  return (
    <div className="ar-details__filters-wrapper">
      <Collapse>
        <Collapse.Panel key="1" header={'Apply Filters'}>
          <form onSubmit={handleSubmit}>
            <Row justify={'center'} align={'middle'}>
              <Col span={4}>
                <div className="c-form-field">
                  <div className="c-form-field__label">KPI Period</div>
                  <div className="c-form-field__wrapper">
                    <Select
                      options={kpiPeriodOptions}
                      value={values.kpiPeriodId}
                      className="c-select"
                      disabled={isKPIPeriodDisabled}
                      onChange={(v) => {
                        return setFieldValue('kpiPeriodId', v);
                      }}
                      allowClear
                    />
                  </div>
                </div>
              </Col>
              <Col span={4} offset={1}>
                <div className="c-form-field">
                  <div className="c-form-field__label">Closed Won Start</div>
                  <div className="c-form-field__wrapper">
                    <DatePicker
                      value={values.closedWonStart}
                      picker="date"
                      format={'YYYY-MM-DD'}
                      className="c-date-field"
                      name="closedWonStart"
                      disabled={!!values.kpiPeriodId}
                      onChange={(v) => {
                        if (v === null) {
                          return setFieldValue('closedWonStart', undefined);
                        } else {
                          return setFieldValue('closedWonStart', v);
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col span={4} offset={1}>
                <div className="c-form-field">
                  <div className="c-form-field__label">Closed Won End</div>
                  <div className="c-form-field__wrapper">
                    <DatePicker
                      value={values.closedWonEnd}
                      picker="date"
                      format={'YYYY-MM-DD'}
                      className="c-date-field"
                      name="closedWonEnd"
                      disabled={!!values.kpiPeriodId}
                      disabledDate={disabledDate}
                      onChange={(v) => {
                        if (v === null) {
                          return setFieldValue('closedWonEnd', undefined);
                        }
                        return setFieldValue('closedWonEnd', v);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col span={4} offset={1}>
                <Button
                  style={{
                    width: '100%',
                    height: 40,
                  }}
                  disabled={isLoading}
                  onClick={resetFormValues}
                >
                  Clear Filters
                </Button>
              </Col>

              <Col span={4} offset={1}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: '100%',
                    height: 40,
                  }}
                  disabled={isApplyFilterDisabled}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </form>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default Filters;
