import { DollarOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../store/hooks';
import { selectKpiPeriodDefaultId } from '../admin/redux/selectors';
import { selectUser } from '../authentication/redux/selectors';
import Filters from './filters/Filters';
import Notes from './notes/Notes';
import { FetchDetailReqBody, fetchDetails } from './redux/async_thunks';
import { selectDetail, selectDetailTotals } from './redux/selectors';
import { setNotes } from './redux/slice';
import { Client } from './redux/types';
import StatsCard from './stats_card/StatsCard';

const DATA_FORMAT = 'DD MMM, YYYY';

const Details = () => {
  const dispatch = useAppDispatch();
  const [isBulkNote, setIsBulkNote] = useState(false);
  const [isDetailLoading, setIsDetailLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const [notesClientId, setNotesClientId] = useState<number | undefined>(
    undefined,
  );

  const user = useSelector(selectUser);

  const kpiPeriodDefaultId = useSelector(selectKpiPeriodDefaultId);

  const detailData = useSelector(selectDetail);

  const detailTotals = useSelector(selectDetailTotals);

  type FilterOpts = {
    kpiPeriodId?: number | null;
    clientType?: string;
    paymentTerms?: string;
    advisor?: string;
    status?: string;
    searchValue?: string;
  };

  const fetchData = async (values?: FilterOpts) => {
    if (user && kpiPeriodDefaultId) {
      try {
        const data: FetchDetailReqBody = {
          userId: user.user_id,
          sessionId: user.session_id,
          kpiPeriodId: kpiPeriodDefaultId,
          closedWonStart: null,
          closedWonEnd: null,
        };

        await dispatch(
          fetchDetails({
            token: user.token,
            reqBody: values ? { ...data, ...values } : data,
          }),
        ).unwrap();

        setIsDetailLoading(false);
      } catch (error) {
        setIsDetailLoading(false);
      }
    }
  };

  const onFilter = (values?: any) => {
    setIsDetailLoading(true);
    fetchData(values);
  };

  const fetchInitialData = async () => {
    try {
      if (user) {
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && kpiPeriodDefaultId) {
      fetchInitialData();
    }
  }, [user, kpiPeriodDefaultId]);

  const columns: TableProps<Client>['columns'] = useMemo(
    () => [
      {
        title: 'Notes',
        dataIndex: 'name',
        key: 'name',
        width: 60,
        fixed: 'left',
        render: (text, record) => {
          return (
            <span className="ar-details__notes-btn">
              <EditOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setNotesClientId(record.id);
                  setIsNotesModalVisible(true);
                }}
              />
            </span>
          );
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 250,
        fixed: 'left',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        fixed: 'left',
        width: 250,
      },

      {
        title: 'Client Type',
        dataIndex: 'clientType',
        key: 'clientType',
        width: 270,
      },
      {
        title: 'Closed Won Date',
        dataIndex: 'closedWonDate',
        key: 'closedWonDate',
        width: 140,
        render: (text) => {
          return dayjs(text).format(DATA_FORMAT);
        },
      },
      {
        title: 'Advisor',
        dataIndex: 'advisor',
        key: 'advisor',
        width: 160,
      },
      {
        title: 'Consultant',
        dataIndex: 'consultant',
        key: 'consultant',
        width: 160,
      },
      {
        title: 'Agreed Fee',
        dataIndex: 'agreedFee',
        key: 'agreedFee',
        width: 100,
      },
      {
        title: 'Payment Terms',
        dataIndex: 'paymentTerms',
        key: 'paymentTerms',
        width: 130,
      },
      {
        title: 'Referred By',
        dataIndex: 'referredBy',
        key: 'referredBy',
        width: 120,
      },
      {
        title: 'Source Funnel',
        dataIndex: 'sourceFunnel',
        key: 'sourceFunnel',
        width: 190,
      },
      {
        title: 'Number Of Properties',
        dataIndex: 'numberOfProperties',
        key: 'numberOfProperties',
        width: 190,
      },
      {
        title: 'Number Of Calls',
        dataIndex: 'numberOfCalls',
        key: 'numberOfCalls',
        width: 140,
      },
      {
        title: 'Referred To',
        dataIndex: 'referredTo',
        key: 'referredTo',
        width: 160,
      },
      {
        title: 'BDM',
        dataIndex: 'bdm',
        key: 'bdm',
        width: 100,
      },
      {
        title: 'Invoice Number #1',
        children: [
          {
            title: 'Invoice Number',
            dataIndex: 'invoiceNumber1',
            key: 'invoiceNumber1',
            width: 140,
          },
          {
            title: 'INV #1 (Excl GST)',
            dataIndex: 'inv1_NetAmount',
            key: 'inv1_NetAmount',
            width: 140,
          },
          {
            title: 'Due Date',
            dataIndex: 'inv1_DueDate',
            key: 'inv1_DueDate',
            width: 110,
            render: (text) => text && dayjs(text).format(DATA_FORMAT),
          },
          {
            title: 'INV Days',
            dataIndex: 'inv1_Days',
            key: 'inv1_Days',
            width: 90,
          },
          {
            title: 'Status',
            dataIndex: 'inv1_Status',
            key: 'inv1_Status',
            width: 90,
          },
          {
            title: 'Paid Date',
            dataIndex: 'inv1_PaidDate',
            key: 'inv1_PaidDate',
            width: 120,
            render: (text) => text && dayjs(text).format(DATA_FORMAT),
          },
          {
            title: 'Payment Days',
            dataIndex: 'inv1_PaymentDays',
            key: 'inv1_PaymentDays',
            width: 120,
          },
        ],
      },
      {
        title: 'Invoice Number #2',
        children: [
          {
            title: 'Invoice Number',
            dataIndex: 'invoiceNumber2',
            key: 'invoiceNumber2',
            width: 140,
          },
          {
            title: 'INV #2 (Excl GST)',
            dataIndex: 'inv2_NetAmount',
            key: 'inv2_NetAmount',
            width: 140,
          },
          {
            title: 'Due Date',
            dataIndex: 'inv2_DueDate',
            key: 'inv2_DueDate',
            width: 110,
            render: (text) => text && dayjs(text).format(DATA_FORMAT),
          },
          {
            title: 'INV Days',
            dataIndex: 'inv2_Days',
            key: 'inv2_Days',
            width: 90,
          },
          {
            title: 'Status',
            dataIndex: 'inv2_Status',
            key: 'inv2_Status',
            width: 90,
          },
          {
            title: 'Paid Date',
            dataIndex: 'inv2_PaidDate',
            key: 'inv2_PaidDate',
            width: 120,
            render: (text) => text && dayjs(text).format(DATA_FORMAT),
          },
          {
            title: 'Payment Days',
            dataIndex: 'inv2_PaymentDays',
            key: 'inv2_PaymentDays',
            width: 120,
          },
        ],
      },
      {
        title: 'Invoice Number #3',
        children: [
          {
            title: 'Invoice Number',
            dataIndex: 'invoiceNumber3',
            key: 'invoiceNumber3',
            width: 140,
          },
          {
            title: 'INV #3 (Excl GST)',
            dataIndex: 'inv3_NetAmount',
            key: 'inv3_NetAmount',
            width: 140,
          },
          {
            title: 'Due Date',
            dataIndex: 'inv3_DueDate',
            key: 'inv3_DueDate',
            width: 110,
            render: (text) => text && dayjs(text).format(DATA_FORMAT),
          },
          {
            title: 'INV Days',
            dataIndex: 'inv3_Days',
            key: 'inv3_Days',
            width: 90,
          },
          {
            title: 'Status',
            dataIndex: 'inv3_Status',
            key: 'inv3_Status',
            width: 90,
          },
          {
            title: 'Paid Date',
            dataIndex: 'inv3_PaidDate',
            key: 'inv3_PaidDate',
            width: 120,
            render: (text) => text && dayjs(text).format(DATA_FORMAT),
          },
          {
            title: 'Payment Days',
            dataIndex: 'inv3_PaymentDays',
            key: 'inv3_PaymentDays',
            width: 120,
          },
        ],
      },
      {
        title: 'Total Invoice Amounts (Excl GST)',
        dataIndex: 'totalInvoiceAmounts',
        key: 'totalInvoiceAmounts',
        width: 250,
      },
      {
        title: 'Paid Amount',
        dataIndex: 'paidAmount',
        key: 'paidAmount',
        width: 150,
      },
      {
        title: 'Remaining Amount',
        dataIndex: 'remainingAmount',
        key: 'remainingAmount',
        width: 150,
      },
    ],
    [],
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleNotesModalClose = () => {
    setIsNotesModalVisible(false);
    setIsBulkNote(false);
    dispatch(setNotes([]));
  };

  const handleBulkNotesClick = () => {
    setIsBulkNote(true);
    setIsNotesModalVisible(true);
  };

  return (
    <div className="ar-details">
      {isNotesModalVisible && (
        <Notes
          handleClose={handleNotesModalClose}
          clientId={notesClientId}
          // @ts-ignore
          clientIds={selectedRowKeys}
          IsBulk={isBulkNote}
        />
      )}
      <Filters onFilter={onFilter} isLoading={isDetailLoading} />
      <Row gutter={[20, 16]} style={{ marginBottom: 20 }} justify={'center'}>
        <Col span={6}>
          <StatsCard
            icon={<DollarOutlined />}
            label="Total Invoice Amount"
            stats={detailTotals?.totalInvoiceAmount || 0}
            formatNumber={true}
            background={`#E6FFFB`}
            color={`#36CFC9`}
          />
        </Col>
        <Col span={6} offset={3}>
          <StatsCard
            icon={<DollarOutlined />}
            label="Total Paid Amount"
            stats={detailTotals?.totalPaidAmount || 0}
            formatNumber={true}
            background={`#E6FFFB`}
            color={`#36CFC9`}
          />
        </Col>
        <Col span={6} offset={3}>
          <StatsCard
            icon={<DollarOutlined />}
            label="Total Remaining Amount"
            stats={detailTotals?.totalRemainingAmount || 0}
            formatNumber={true}
            background={`#E6FFFB`}
            color={`#36CFC9`}
          />
        </Col>
      </Row>
      {selectedRowKeys.length > 1 && (
        <Row style={{ marginBottom: 10 }} justify={'end'}>
          <Col>
            <Button type="primary" onClick={handleBulkNotesClick}>
              Bulk Notes
            </Button>
          </Col>
        </Row>
      )}
      <Table
        dataSource={detailData}
        bordered
        size="middle"
        columns={columns}
        scroll={{ x: 5400 }}
        loading={isDetailLoading}
        rowKey={'id'}
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default Details;
