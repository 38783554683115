import { Row } from 'antd';
import { Chart as ChartJS, registerables } from 'chart.js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectOverdueInvoicesTypes } from '../../details/redux/selectors';
import {
  selectDashboardInvoiceStatusTracker,
  selectDashboardOverdueInvoices,
  selectDashboardPaymentTerms,
} from '../redux/selectors';
import DoughnutChartCard from './DoughnutChartCard';

// Register all the necessary components for ChartJS
ChartJS.register(...registerables);

const Charts = ({ onFilter }: { onFilter: (v: any) => void }) => {
  const [overdueFilter, setOverdueFilter] = useState('PaymentTerms');
  // Select various pieces of state from the Redux store
  const invoiceStatusTracker = useSelector(selectDashboardInvoiceStatusTracker);
  const paymentTerms = useSelector(selectDashboardPaymentTerms);
  const overdueInvoices = useSelector(selectDashboardOverdueInvoices);
  const overdueInoicesTypes = useSelector(selectOverdueInvoicesTypes);

  const handleOverdueInvoiceFilter = (v: string) => {
    onFilter({ overdueInvoicesBy: v });
    setOverdueFilter(v);
  };

  return (
    <Row gutter={[16, 0]} justify="center">
      <DoughnutChartCard
        title="Invoice Status Tracker"
        data={invoiceStatusTracker}
      />
      <DoughnutChartCard title="Payment Terms" data={paymentTerms} />
      <DoughnutChartCard
        title="Overdue Invoices"
        data={overdueInvoices}
        hasFilter
        filterOptions={overdueInoicesTypes}
        onFilterChange={handleOverdueInvoiceFilter}
        filterValue={overdueFilter}
      />
    </Row>
  );
};

export default Charts;
