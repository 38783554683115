import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createKpiPeriod,
  deleteKpiPeriod,
  fetchKpiPeriods,
  updateKpiPeriod,
} from './async_thunks';
import { AdminState } from './types';

const initialState: AdminState = {
  // Filter
  values: {
    kpi_periods: [],
  },
  // Admin settings
  kpi_periods_list: [],
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setKpiPeriods: (state, action: PayloadAction<any>) => {
      state.values.kpi_periods = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchKpiPeriods.fulfilled, (state, action) => {
      state.kpi_periods_list = action.payload;
    });
    builder.addCase(fetchKpiPeriods.rejected, (state, action) => {
      state.kpi_periods_list = initialState.kpi_periods_list;
    });
    builder.addCase(createKpiPeriod.fulfilled, (state, action) => {
      state.kpi_periods_list = action.payload;
    });
    builder.addCase(updateKpiPeriod.fulfilled, (state, action) => {
      const updatedKpiPeriod = action.payload;

      state.kpi_periods_list = state.kpi_periods_list.map((kpiPeriod) =>
        kpiPeriod.kpiPeriodId === updatedKpiPeriod.kpiPeriodId
          ? updatedKpiPeriod
          : kpiPeriod,
      );
    });
    builder.addCase(deleteKpiPeriod.fulfilled, (state, action) => {
      state.kpi_periods_list = action.payload;
    });
  },
});

export const { setKpiPeriods } = adminSlice.actions;

export default adminSlice.reducer;
