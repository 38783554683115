import { Button, DatePicker, Form, Input, Modal, notification } from 'antd';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import FullPageLoader from '../../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../../../../store/hooks';
import { selectUser } from '../../../../authentication/redux/selectors';
import { createKpiPeriod, updateKpiPeriod } from '../../../redux/async_thunks';
import { KPIPeriod } from '../../../redux/types';

type KpiPeriodFormProps = {
  handleClose: () => void;
  dateFormat: string;
  kpiPeriodEdit: KPIPeriod | null;
};

const KpiPeriodForm = ({
  handleClose,
  dateFormat,
  kpiPeriodEdit,
}: KpiPeriodFormProps) => {
  const DEFAULT_VALUES: {
    name: string;
    startDate: string | null;
    endDate: string | null;
  } = {
    name: '',
    startDate: null,
    endDate: null,
  };

  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date()
      .required('End Date is required')
      .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
  });

  const addKpiPeriod = async (values) => {
    if (user) {
      try {
        setIsLoading(true);

        const data = {
          userId: user.user_id,
          sessionId: user.session_id,
        };

        await dispatch(
          createKpiPeriod({
            token: user.token,
            reqBody: values ? { ...data, ...values } : data,
          }),
        ).unwrap();

        setIsLoading(false);
        handleClose();
        notification.success({
          message: 'Success!',
          description: 'KPI Period has been added.',
        });
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const editKpiPeriod = async (values) => {
    if (user && kpiPeriodEdit) {
      try {
        setIsLoading(true);

        const data = {
          userId: user.user_id,
          sessionId: user.session_id,
          kpiPeriodId: kpiPeriodEdit?.kpiPeriodId,
        };

        await dispatch(
          updateKpiPeriod({
            token: user.token,
            reqBody: values ? { ...data, ...values } : data,
          }),
        ).unwrap();

        setIsLoading(false);
        handleClose();
        notification.success({
          message: 'Success!',
          description: 'KPI Period has been edited.',
        });
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: kpiPeriodEdit
      ? {
          name: kpiPeriodEdit.kpiPeriod,
          startDate: kpiPeriodEdit.kpiStart,
          endDate: kpiPeriodEdit.kpiEnd,
        }
      : DEFAULT_VALUES,
    validationSchema,
    onSubmit: (values) => {
      const data = {
        kpiPeriod: values.name,
        kpiStart: values.startDate,
        kpiEnd: values.endDate,
      };

      if (kpiPeriodEdit) {
        editKpiPeriod(data);
      } else {
        addKpiPeriod(data);
      }
    },
  });

  const formatDateValue = (date: string | null) => {
    return date ? dayjs(date) : null;
  };

  return (
    <Modal
      width={400}
      title={`${kpiPeriodEdit ? 'Edit' : 'Add'} KPI Period`}
      open={true}
      maskClosable={true}
      onCancel={handleClose}
      footer={null}
      centered
      className="l-admin__modal"
    >
      {isLoading && <FullPageLoader style={{ borderRadius: '8px' }} />}
      <Form onFinish={formik.handleSubmit} layout="vertical">
        <Form.Item
          label="Name"
          validateStatus={
            formik.errors.name && formik.touched.name ? 'error' : ''
          }
          help={
            formik.errors.name && formik.touched.name ? formik.errors.name : ''
          }
        >
          <Input
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder="Enter KPI Period Name"
          />
        </Form.Item>
        <Form.Item
          label="Start Date"
          validateStatus={
            formik.errors.startDate && formik.touched.startDate ? 'error' : ''
          }
          help={
            formik.errors.startDate && formik.touched.startDate
              ? formik.errors.startDate
              : ''
          }
        >
          <DatePicker
            style={{ width: '100%' }}
            id="startDate"
            name="startDate"
            value={formatDateValue(formik.values.startDate)}
            onChange={(date, dateString) => {
              formik.setFieldValue('startDate', dateString);
            }}
            format={dateFormat}
            placeholder="Select Start Date"
          />
        </Form.Item>
        <Form.Item
          label="End Date"
          validateStatus={
            formik.errors.endDate && formik.touched.endDate ? 'error' : ''
          }
          help={
            formik.errors.endDate && formik.touched.endDate
              ? formik.errors.endDate
              : ''
          }
        >
          <DatePicker
            style={{ width: '100%' }}
            id="endDate"
            name="endDate"
            value={formatDateValue(formik.values.endDate)}
            onChange={(date, dateString) => {
              formik.setFieldValue('endDate', dateString);
            }}
            format={dateFormat}
            placeholder="Select End Date"
          />
        </Form.Item>
        <div className="l-admin__btn-group">
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default KpiPeriodForm;
