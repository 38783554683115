import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import admin from '../features/admin/redux/slice';
import auth from '../features/authentication/redux/slice';
import dashboard from '../features/dashboard/redux/slice';
import detail from '../features/details/redux/slice';

export interface IRootState {
  router: RouterState;
}

// Root Reducer.
export default (history: History) => ({
  router: connectRouter(history),
  admin,
  auth,
  detail,
  dashboard,
});
